/* eslint-disable no-console */
import React, { useState, useCallback, useContext } from 'react'
import random from 'lodash/random'
import useGTM from '@elgorditosalsero/react-gtm-hook'
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect'
import { addBreadcrumb, Severity } from '@sentry/react'

import type { ABTest } from '../types/abTest'
import { placeholderFunction } from '../utils/logging'
import useEvents from './useEvents'

interface UseContentfulABTestOptions {
  defaultToOriginal?: boolean
}

const GoogleOptimizeLoading = -1

/**
 * useContentfulABTest is a hook that "rolls the dice" on an A/B test provided
 * by Google Optimize.
 *
 * If `defaultToOriginal` to original is `true`, it will return the original
 * variant of the test by default. This is helpful for things like button click
 * actions where the displayed content stays the same and the A/B test is
 * invisible to the end user.
 */
export default function useContentfulABTest<T>(
  abTest: ABTest<T>,
  { defaultToOriginal = false }: UseContentfulABTestOptions = {}
): T | null {
  const { googleOptimizeId } = abTest
  const variants = [abTest.original, ...abTest.variants]
  const { experimentVariants, assign } = useContext(ContentfulABTestContext)

  // Always assign to an experiment, assign is smart enough to only decide once
  // per app load.
  useDeepCompareEffect(() => assign(abTest), [abTest])

  if (
    experimentVariants[googleOptimizeId] !== undefined &&
    experimentVariants[googleOptimizeId] > GoogleOptimizeLoading
  ) {
    return variants[experimentVariants[googleOptimizeId]]
  }

  return defaultToOriginal ? abTest.original : null
}

export const ContentfulABTestContext = React.createContext<{
  experimentVariants: Record<string, number>
  assign: (abTest: ABTest<any>) => void
}>({
  experimentVariants: {},
  assign: (abTest) => placeholderFunction(abTest),
})
ContentfulABTestContext.displayName = 'ContentfulABTestContext'

export const ContentfulABTestProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { sendDataToGTM } = useGTM()
  const sendEvent = useEvents()
  const [experimentVariants, setExperimentVariants] = useState<
    Record<string, number>
  >({})

  const updateExperiment = useCallback(
    (experimentID: string, variantIndex: number, assignmentMethod?: string) =>
      setExperimentVariants((experiments) => {
        const existingExperimentAssignment = experiments[experimentID]

        // If we are already assigning, bail so we only do this once
        if (
          existingExperimentAssignment !== undefined &&
          existingExperimentAssignment > GoogleOptimizeLoading
        ) {
          return experiments
        }

        if (variantIndex > GoogleOptimizeLoading) {
          addBreadcrumb({
            level: Severity.Info,
            category: 'A/B Test',
            message: 'A/B Test Variant Assigned',
            data: {
              [`https://app.contentful.com/spaces/7thvzrs93dvf/entries/${experimentID}`]:
                variantIndex,
              assignmentMethod,
            },
          })

          sendEvent({
            event: 'Google Optimize A/B Test Assigned',
            variables: {
              contentful: {
                experimentID,
                variantIndex,
                assignmentMethod,
              },
            },
          }).catch((e) => console.error('could not post A/B test event', e))
        }

        return {
          ...experiments,
          [experimentID]: variantIndex,
        }
      }),
    [sendEvent]
  )

  const assign = useCallback(
    (abTest: ABTest<any>) => {
      // Set a loading state
      updateExperiment(abTest.googleOptimizeId, GoogleOptimizeLoading)

      // If no Google Analytics, assign randomly
      if (!window.ga) {
        updateExperiment(
          abTest.googleOptimizeId,
          random(0, abTest.variants.length),
          'random, !window.ga'
        )
        return
      }

      // If the Google Optimize object exists and the variant has already been
      // assigned, use it. Ideally, this shouldn't be used and we should rely
      // upon the optimize.callback logic, but we cannot get it working.
      if (window.google_optimize) {
        const possibleVariant = window.google_optimize.get?.(
          abTest.googleOptimizeId
        )

        if (possibleVariant !== undefined) {
          updateExperiment(
            abTest.googleOptimizeId,
            parseInt(possibleVariant),
            'window.google_optimize'
          )
          return
        }
      }

      // This is the "correct way" of assigning a Google Optimize experiment.
      // https://support.google.com/optimize/answer/9059383?hl=en
      // The gist of it is that you pass a callback function to a GTM
      // dataLayer.push and Optimize will call the callback with the correct
      // variant. In practice, I have not seen this work and cannot get it
      // working. It also will not work if Google Analytics is blocked from
      // loading by an ad-blocker.
      //
      // We are going to give this 200ms to determine which variant to use
      // before assigning randomly. Because of how optimize works (it provides
      // the selected variant in the GA script response), this should be more
      // than enough time for us to get the variant, otherwise it'll never work.
      const optimizeRandomTimeout = setTimeout(() => {
        updateExperiment(
          abTest.googleOptimizeId,
          random(0, abTest.variants.length),
          'random, optimize.callback timeout'
        )
      }, 200)

      const callback = function (variantIndex: string, experimentID: string) {
        clearTimeout(optimizeRandomTimeout)
        updateExperiment(
          experimentID,
          parseInt(variantIndex),
          'optimize.callback'
        )
      }

      sendDataToGTM([
        'event',
        'optimize.callback',
        {
          name: abTest.googleOptimizeId,
          callback: callback,
        },
      ])
    },
    [sendDataToGTM, updateExperiment]
  )

  return (
    <ContentfulABTestContext.Provider value={{ experimentVariants, assign }}>
      {children}
    </ContentfulABTestContext.Provider>
  )
}

import { GatsbyBrowser, PluginOptions as GatsbyPluginOptions } from 'gatsby'
import { SiteMetadata } from './hooks/useSiteMetadata'

interface PluginOptions extends GatsbyPluginOptions {
  siteMetadata: SiteMetadata
}

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = (
  { routerProps: { location } },
  { siteMetadata: { defaultResumeProduct } }: PluginOptions
) => {
  // Do not update the scroll when the user is on the pricing page and
  // the URL contains the hash. This is to make the packages' section
  // visible when the Buy Now CTA is clicked.
  if (
    // TopResume/TopCV pricing page
    (location.pathname === `/${defaultResumeProduct.toLowerCase()}-writing` ||
      // TopCV fr pricing page
      location.pathname ===
        `/redaction-${defaultResumeProduct.toLowerCase()}`) &&
    location.hash
  ) {
    return false
  }

  return true
}

import { graphql, useStaticQuery } from 'gatsby'

export interface SiteMetadata {
  title: string
  siteUrl: string
  description: string | null
  brandName: string
  trailingSlash: boolean
  defaultResumeProduct: string
  googleTagManagerCode: string
  intercomWidgetColor: string
  intercomAppId: string
  partnerKey: string
  domainName: string
  locale: string
  i18nextLocale: string
}

interface UseSiteMetadataQuery {
  site: {
    siteMetadata: SiteMetadata
  }
}

export default function useSiteMetadata(): SiteMetadata {
  const data = useStaticQuery<UseSiteMetadataQuery>(
    graphql`
      query UseSiteMetadata {
        site {
          siteMetadata {
            title
            siteUrl
            description
            brandName
            trailingSlash
            defaultResumeProduct
            googleTagManagerCode
            intercomWidgetColor
            intercomAppId
            domainName
            locale
            i18nextLocale
          }
        }
      }
    `
  )

  return data.site.siteMetadata
}

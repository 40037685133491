import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useContext,
} from 'react'
import { AxiosResponse } from 'axios'
import useGTM from '@elgorditosalsero/react-gtm-hook'
import omit from 'lodash/omit'

import useAxios from './useAxios'
import { placeholderFunction } from '../utils/logging'

export interface EventVariables {
  contentful: Record<string, unknown>
  hash: string
  add_to_cart: Record<string, unknown>
  pagination: {
    page: number
    pageSize: number
    totalItems: number
  }
  search_term: string
}

export interface EventPayload {
  event: string
  system: 'products'
  variables: Partial<EventVariables>
}

export type SendEventPayload = Omit<EventPayload, 'system'> & {
  gtm?: boolean
  gtmPayload?: Record<string, unknown>
}

export type EventResponse = Pick<EventPayload, 'event'>

export type SendEvent = (
  data: SendEventPayload
) => Promise<AxiosResponse<EventResponse>>

/**
 * useEvents is a hook that provides a function to submit events to Aramis, an
 * internal event bus, and optionally, Google Tag Manager.
 *
 * In the payload, the event is submitted to GTM if a `gtmPayload` is provided
 * OR if `gtm` is set to true. The latter case will inline the `variables`
 * provided to Aramis in the event to GTM.
 *
 * If you want to subscribe to events in React components, use the
 * `useEventSubscription` hook in this file.
 */
export default function useEvents(): SendEvent {
  const axios = useAxios()
  const { sendDataToGTM } = useGTM()
  const { listeners: internalEventBusListeners } = useContext(EventBusContext)

  return useCallback<SendEvent>(
    async (data) => {
      if (data.gtm || data.gtmPayload) {
        sendDataToGTM(
          data.gtmPayload ?? {
            event: data.event,
            ...data.variables,
          }
        )
      }

      if (internalEventBusListeners[data.event]) {
        internalEventBusListeners[data.event].forEach((cb) => cb(data))
      }

      return axios.post<EventResponse>('/api/v2/events', {
        ...omit(data, ['gtm', 'gtmPayload']),
        system: 'products',
      })
    },
    [axios, sendDataToGTM, internalEventBusListeners]
  )
}

/**
 * useEventSubscription is a hook that allows you to run a callback when an
 * event from `useEvents` is fired from anywhere in the application. This hook
 * will also unsubscribe the event when it is removed from the tree.
 *
 * IMPORTANT: It is crucially important that the callback passed in is wrapped
 * in `useCallback`, otherwise it can be called multiple times. The callbacks
 * are stored in a `Set` which rely on the identity of the functions staying the
 * same between renders.
 */
export function useEventSubscription(
  eventType: string,
  callback: EventCallback
): void {
  const { subscribe, unsubscribe } = useContext(EventBusContext)

  useEffect(() => {
    subscribe(eventType, callback)

    return function cleanup() {
      unsubscribe(eventType, callback)
    }
  }, [eventType, callback, subscribe, unsubscribe])
}

export type EventCallback =
  | ((event: SendEventPayload) => void | Promise<void>)
  | (() => void | Promise<void>)

export interface EventBusData {
  listeners: Record<string, Set<EventCallback>>
  subscribe: (eventType: string, callback: EventCallback) => void
  unsubscribe: (eventType: string, callback: EventCallback) => void
}

const EventBusContext = React.createContext<EventBusData>({
  listeners: {},
  subscribe: (eventType, callback) => placeholderFunction(eventType, callback),
  unsubscribe: (eventType, callback) =>
    placeholderFunction(eventType, callback),
})
EventBusContext.displayName = 'EventBusContext'

export const EventBusProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [listeners, setListeners] = useState<
    Record<string, Set<EventCallback>>
  >({})
  const subscribe = useCallback(
    (eventType: string, callback: EventCallback) =>
      setListeners((eventListeners) => {
        if (!eventListeners?.[eventType]?.add(callback)) {
          eventListeners[eventType] = new Set<EventCallback>([callback])
        }

        return eventListeners
      }),
    []
  )
  const unsubscribe = useCallback(
    (eventType: string, callback: EventCallback) =>
      setListeners((eventListeners) => {
        if (eventListeners[eventType]) {
          eventListeners[eventType].delete(callback)

          if (eventListeners[eventType].size === 0) {
            delete eventListeners[eventType]
          }
        }

        return eventListeners
      }),
    []
  )
  const value = useMemo<EventBusData>(
    () => ({
      listeners,
      subscribe,
      unsubscribe,
    }),
    [listeners, subscribe, unsubscribe]
  )

  return (
    <EventBusContext.Provider value={value}>
      {children}
    </EventBusContext.Provider>
  )
}

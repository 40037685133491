// https://stackoverflow.com/a/7791100
export const emailRegex =
  // eslint-disable-next-line no-useless-escape
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const alternativeEmailRegex =
  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/

export const urlRegex =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

export const parserRegex = {
  brand: /\{brand\}/g,
  product: /\{product\}/gi,
}

export function isEmail(value: string): boolean {
  return alternativeEmailRegex.test(value)
}

export const talentincEmailRegex = /^[A-Za-z0-9._%+-]+@talentinc\.com$/

export function isTalentIncEmail(
  email: string
): email is `${string}@talentinc.com` {
  return talentincEmailRegex.test(email)
}

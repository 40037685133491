/* eslint-disable @typescript-eslint/no-var-requires, no-use-before-define */
const React = require('react')
const RootElementProvider =
  require('@talentinc/gatsby-theme-ecom/components/Providers/RootElement').default
const TopCVCoUKTheme = require('./src/styles').default

exports.wrapRootElement = ({ element }) => {
  return (
    <RootElementProvider theme={TopCVCoUKTheme}>{element}</RootElementProvider>
  )
}

import React, { useContext } from 'react'
import useMeasure, {
  UseMeasureResult,
  UseMeasureRect,
} from 'react-use/lib/useMeasure'

interface PageMeasurementProviderValues {
  header:
    | UseMeasureResult<HTMLElement>
    | [React.RefObject<HTMLElement>, UseMeasureRect]
  footer:
    | UseMeasureResult<HTMLElement>
    | [React.RefObject<HTMLElement>, UseMeasureRect]
}

const defaultMeasurement = Object.freeze<UseMeasureRect>({
  x: 0,
  y: 0,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  height: 0,
  width: 0,
})

const PageMeasurementContext =
  React.createContext<PageMeasurementProviderValues>({
    header: [React.createRef<HTMLElement>(), defaultMeasurement],
    footer: [React.createRef<HTMLElement>(), defaultMeasurement],
  })

export const PageMeasurementProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const header = useMeasure()
  const footer = useMeasure()

  return (
    <PageMeasurementContext.Provider
      value={{
        header,
        footer,
      }}
    >
      {children}
    </PageMeasurementContext.Provider>
  )
}

/**
 * usePageMeasurements provides measurements of common elements in the page,
 * like the header and footer. This allows for components not in the same tree
 * to use them to offset scroll heights and other things along those lines.
 */
export default function usePageMeasurements(): PageMeasurementProviderValues {
  return useContext(PageMeasurementContext)
}

export enum ZipJobConstants {
  BrandName = 'ZipJob',
  ImpactPartnerKey = 'zj',
  ExecutivePurchaseURL = '/purchase/B0qd82z',
}

export enum PageDataTypes {
  LandingPageV2 = 'Landing Pages V2',
  ProductPage = 'Product Pages',
}

export enum GAConstants {
  Event = 'event',
  EventViewItemList = 'view_item_list',
  EventViewItem = 'view_item',
  Fired = 'fired',
}

// @TODO (tech-debt): Add the remaining `zIndex`es
export enum ZIndexes {
  Loader = 100000,
}

export enum Timeouts {
  EmailLookup = 4000,
  RefetchSession = 1200,
}

// @TODO: Add the remaining endpoints
export enum Endpoints {
  TRDesktopResumeUpload = '/api/v2/leads/resume?file_only=1',
  TRMobileResumeUpload = '/api/v2/leads/resume',
  LeadsEmail = '/api/v2/leads/email',
  Stats = '/api/v2/events/stats',
  ContactFormZJ = '/api/v2/messages/contact-form',
  ContactFormCommon = 'api/v2/msg/transactional/internal',
  Session = '/api/v2/session',
  CritiqueState = '/api/v2/session/critique-state',
}

export enum Sizes {
  OneByte = 1,
  OneMbBytes = 1e6,
}
export enum CookiesEnum {
  Frontend24HourVisit = 'frontend_visit_24hr_cookie',
  ReviewPage24HourVisit = 'review_page_visit_24hr_cookie',
  CheckoutPage24HourVisit = 'checkout_visit_24hr_cookie',
}

//The "/free-review/" has a trailing forward slash to account for zipjob
export const reviewPagesSet = new Set([
  '/resume-review',
  '/cv-review',
  '/free-review/',
])

export const checkoutPagesSet = new Set([
  '/resume-writing',
  '/cv-writing',
  '/services/',
])

export enum MediaQueries {
  TabletAndBelow = '(max-width: 768px)',
}
export const MIN_AFFIRM_AMOUNT = 99
export const showMoreIncrements = 6

export enum ReferenceTextCopyKeys {
  OrderNow = 'Order Now',
  Price = 'Price',
}

export const CTAClickSuffix = '_click'

import React, { useState, useCallback, useContext, useMemo } from 'react'
import useAsyncFn from 'react-use/lib/useAsyncFn'
import { placeholderFunction } from '@talentinc/gatsby-theme-ecom/utils/logging'
import { AxiosError, AxiosResponse, toFormData } from 'axios'

import useAxios from './useAxios'
import { emailRegex, isTalentIncEmail } from '../utils/validation'
import { AllowedFileSizes } from '../types/widget'
import { Endpoints } from '../types/constants'
import useCommonTRResumeUploadPayload from './useCommonTRResumeUploadPayload'
import { useSessionQuery } from '../components/Providers/UserSession'
import useSiteMetadata from './useSiteMetadata'

export interface ResumeUploadPayload {
  resume_file: File
  email: string
  first_name?: string
  last_name?: string
  utm_source?: string
  site?: string
  country?: string
  tags?: Record<string, string>
}

export interface ResumeUploadSuccessResponse {
  status: {
    code: '200'
  }
  data: {
    resume_id: string
    system?: string
    accepted_tags?: Record<string, string>
  }
}

export interface ResumeUploadFailureResponse {
  status: {
    code: '400' | '409' | '500' | string
    message: string
  }
  data: {
    error: string
  }
}

interface Errors {
  email: string | Error | null
  resumeFile: string | Error | null
}

export interface LeadActionsContextType {
  state: {
    email: string
    resumeFile: File | null
    checksum: string
  }
  errors: Errors
  resumeSubmission: {
    submit: () => void | Promise<
      void | AxiosResponse<ResumeUploadSuccessResponse> | false
    >
    state: ReturnType<typeof useAsyncFn>[0]
    valid: boolean
  }
  newsletterSubmission: {
    submit: () => void | Promise<void | false>
    state: ReturnType<typeof useAsyncFn>[0]
    valid: boolean
  }
  setEmail: (email: string) => void
  setResumeFile: (resumeFile: File) => boolean
  setErrors: (errors: Partial<Errors>) => void
  setChecksum: (checksum: string) => void
  clearState: () => void
  isEmptyFile: (resumeFile: File) => Promise<boolean>
}

export const allowedUploadExtensions = Object.freeze([
  '.doc',
  '.docx',
  '.pdf',
  '.txt',
  '.rtf',
  '.odt',
  '.pages',
  '.html',
  '.png',
  '.gif',
  '.tiff',
] as const)

const LeadActionsContext = React.createContext<LeadActionsContextType>({
  state: {
    email: '',
    resumeFile: null,
    checksum: '',
  },
  errors: {
    email: null,
    resumeFile: null,
  },
  resumeSubmission: {
    submit: placeholderFunction,
    state: {
      loading: false,
    },
    valid: false,
  },
  newsletterSubmission: {
    submit: placeholderFunction,
    state: {
      loading: false,
    },
    valid: false,
  },
  setErrors: (errors) => placeholderFunction(errors),
  setEmail: (email) => placeholderFunction(email),
  setResumeFile: (resumeFile) => {
    placeholderFunction(resumeFile)
    return false
  },
  setChecksum: (checksum) => placeholderFunction(checksum),
  clearState: placeholderFunction,
  isEmptyFile: async (_resumeFile: File) => true,
})

export const LeadActionsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const commonUploadPayload = useCommonTRResumeUploadPayload()
  const axios = useAxios()
  const { data: sessionData } = useSessionQuery()
  const { brandName } = useSiteMetadata()

  const isZipJob = brandName.toLocaleLowerCase() === 'zipjob'

  const [state, setState] = useState<LeadActionsContextType['state']>({
    email: '',
    resumeFile: null,
    checksum: '',
  })
  const [errors, rawSetErrors] = useState<LeadActionsContextType['errors']>({
    email: null,
    resumeFile: null,
  })
  const [resumeSubmissionState, uploadResume] = useAsyncFn(
    async (payload?: ResumeUploadPayload) => {
      if (!payload) {
        if (state.resumeFile) {
          payload = {
            email: state.email,
            resume_file: state.resumeFile,
          }
        } else {
          return false
        }
      }

      if (isZipJob || isTalentIncEmail(payload.email)) {
        const leadData = toFormData(payload, commonUploadPayload)

        return axios
          .post(Endpoints.TRMobileResumeUpload, leadData, {
            auth: {
              username: atob(sessionData?.pt ?? ''),
              password: atob(sessionData?.st ?? ''),
            },
          })
          .catch((error: unknown) => {
            if ((error as AxiosError).isAxiosError) {
              // NOTE: status 409 happen when resume already exists in database
              return (error as AxiosError).response?.status !== 409
                ? Promise.reject(error)
                : { status: 200 }
            }

            return Promise.reject(error)
          }) as any
      }

      const data = toFormData(payload)
      return await axios.post<ResumeUploadSuccessResponse>(
        '/api/v2/resumes',
        data
      )
    },
    [axios, state]
  )

  const setErrors = useCallback(
    (errors: Partial<Errors>) =>
      rawSetErrors((e) => ({
        ...e,
        ...errors,
      })),
    []
  )

  const setEmail = useCallback(
    (email: string) =>
      setState((s) => {
        // If they type, remove the error
        setErrors({ email: null })
        return { ...s, email }
      }),
    [setErrors]
  )

  const setResumeFile = useCallback(
    (resumeFile: File) => {
      // Make sure the file has an allowed extension
      const acceptableExtension = allowedUploadExtensions
        .map((ext) => ext.split('.').pop())
        .includes(resumeFile.name.split('.').pop())

      if (!acceptableExtension) {
        setErrors({ resumeFile: 'Incorrect file type' })
        return false
      }

      if (resumeFile.size < AllowedFileSizes.MinSize) {
        setErrors({ resumeFile: 'File size is too small' })
        return false
      }

      if (resumeFile.size > AllowedFileSizes.MaxSize) {
        setErrors({ resumeFile: 'File size is too large' })
        return false
      }

      setErrors({ resumeFile: null })
      setState((s) => ({ ...s, resumeFile }))

      return true
    },
    [setErrors]
  )

  const setChecksum = useCallback(
    (checksum: string) => setState((s) => ({ ...s, checksum })),
    []
  )

  const clearState = useCallback(() => {
    setState((s) => ({
      ...s,
      resumeFile: null,
      email: '',
      checksum: '',
    }))
    setErrors({
      email: null,
      resumeFile: null,
    })
  }, [setErrors])

  const isEmptyFile = useCallback(
    async (resumeFile: File) => {
      const fileContent = await resumeFile.text()
      if (fileContent.trim().length > 0) {
        return false
      }
      setErrors({ resumeFile: 'File is empty' })
      return true
    },
    [setErrors]
  )

  const memoedValue = useMemo(
    () => ({
      state,
      resumeSubmission: {
        submit: uploadResume,
        state: resumeSubmissionState,
        valid:
          !!state.email && emailRegex.test(state.email) && !!state.resumeFile,
      },
      newsletterSubmission: {
        submit: placeholderFunction,
        state: {
          loading: false,
        },
        valid: !!state.email && emailRegex.test(state.email),
      },
      errors,
      setErrors,
      setEmail,
      setResumeFile,
      setChecksum,
      clearState,
      isEmptyFile,
    }),
    [
      clearState,
      errors,
      isEmptyFile,
      resumeSubmissionState,
      setEmail,
      setErrors,
      setResumeFile,
      setChecksum,
      state,
      uploadResume,
    ]
  )

  return (
    <>
      <LeadActionsContext.Provider value={memoedValue}>
        {children}
      </LeadActionsContext.Provider>
    </>
  )
}

export default function useLeadActions(): LeadActionsContextType {
  const context = useContext(LeadActionsContext)
  if (!context) {
    throw new Error(
      'useLeadActions can only be used within LeadActionsProvider'
    )
  }
  return context
}

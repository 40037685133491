import React from 'react'
import useLocation from 'react-use/lib/useLocation'

import { CommonPayload, ICommonUploadPayload } from '../types/widget'
import { isBrowser } from '../utils/env'

// This is a constructor to get common resume upload payloads
export default function useCommonTRResumeUploadPayload(): FormData | undefined {
  const { hostname } = useLocation()

  const commonPayload = React.useMemo(
    () => ({
      referring_url: isBrowser ? window?.location.href : '',
      utm_lead: CommonPayload.UtmLead,
      upload_source: hostname ?? '',
    }),
    [hostname]
  ) satisfies ICommonUploadPayload

  const payload = React.useMemo(() => {
    // For successfully building as FormData is not defined
    if (!isBrowser) {
      return undefined
    }

    return Object.entries(commonPayload).reduce((formData, [key, value]) => {
      formData.append(key, value)
      return formData
    }, new FormData())
  }, [commonPayload])

  return payload
}

import { ThemeOptions, createTheme } from '@mui/material'
import merge from 'lodash/merge'

export const colors = {
  blue: {
    10: '#eaF6ff',
    50: '#1a91f0',
    60: '#1170cd',
    100: '#eaf4fc',
    400: '#589fee',
    700: '#1b3c5e',
  },
  gray: {
    100: '#e7e7e7',
    200: '#d3d3d3',
    300: '#a7a7a7',
    400: '#7a7a7a',
    a400: '#909090',
    500: '#646464',
    a500: '#687374', // disabled primary button text color
    700: '#222222',
    a700: '#23252c',
  },
  green: {
    100: '#eef8ee',
    200: '#b5d8d8',
    400: '#76b56d',
    700: '#294425',
  },
  orange: {
    100: '#fcf4e7',
    200: '#f5cd8a',
    400: '#ef9f44',
    500: '#f4a44a',
    600: '#b95227',
    700: '#613e10',
    a500: '#e56914',
    a100: '#d76f31',
  },
  red: {
    100: '#faedeb',
    400: '#e46253',
    700: '#5a2019',
  },
  cyan: {
    100: '#f3fafb',
    200: 'rgba(4,50,50,.3)',
    300: '#0A8080',
    a300: '#006563',
    400: '#126767',
    a400: '#1F4D4E', // disabled button color for cyan[400]
    600: '#2a4446',
    700: '#273739',
    800: '#313131',
  },
  white: {
    100: '#ffffff',
    200: '#f4f2f0',
    300: '#f8f5f2',
    400: '#faf8f6',
  },
  // Not the ideal way to do it, but we have conflicts within the TR and ZJ palettes
  // @TODO: Standardise the theme palette for all websites
  switch: {
    active: '#c97340',
  },
  dns: {
    background: '#2a3739',
    text: '#ffffff',
    statusBackgroundSuccess: '#bcd7d7',
    statusBackgroundFailure: '#faf8f6',
    statusMessageSuccess: '#306566',
    statusMessageFailure: '#da0c22',
  },
  backgrounds: {
    grey: '#f7f9fc',
    black: '#202124',
  },
  neutral: {
    5: '#f7f9fc',
    10: '#eff2f9',
    20: '#d9deeb',
    15: '#e7eaf4',
    30: '#bec4d5',
    40: '#9FA6BB',
    50: '#828ba2',
    60: '#656e83',
    80: '#303848',
    90: '#1e2532',
  },
  amber: {
    10: '#fff2cc',
    40: '#ec930c',
    50: '#cf760d',
    90: '#3e1d0a',
  },
}

export const ZipJobTheme = createTheme({
  themeName: 'ZipJob',
  colors,
  palette: {
    primary: {
      light: colors.cyan[100],
      main: colors.cyan[400],
      dark: colors.cyan[700],
      contrastText: colors.cyan[600],
    },
    default: {
      light: colors.cyan[100],
      main: colors.cyan[400],
      dark: colors.cyan[700],
      contrastText: colors.cyan[600],
    },
    secondary: {
      light: colors.orange[200],
      main: colors.orange[400],
      dark: colors.orange[600],
    },
    error: {
      light: colors.red[100],
      main: colors.red[400],
      dark: colors.red[700],
    },
    warning: {
      light: colors.orange[100],
      main: colors.orange[500],
      dark: colors.orange[700],
    },
    info: {
      light: colors.blue[100],
      main: colors.blue[400],
      dark: colors.blue[700],
    },
    success: {
      light: colors.green[100],
      main: colors.green[400],
      dark: colors.green[700],
    },
    text: {
      primary: colors.gray[700],
      secondary: colors.gray[500],
      disabled: colors.gray[300],
      hint: colors.gray[400],
    },
    action: {
      active: colors.cyan[400],
      hover: colors.cyan[300],
      selected: colors.cyan[300],
      disabled: colors.cyan[400],
      focus: colors.green[200],
    },
    background: {
      default: colors.white[100],
      paper: colors.white[400],
    },
    white: {
      main: '#fff',
    },
    common: {
      white: colors.white[300],
    },
    divider: colors.gray[200],
    newColors: {
      greyBackground: colors.backgrounds.grey,
      blackBackground: colors.backgrounds.black,
    },
  },
  typography: {
    fontFamily: 'Lato, Trebuchet MS, Avant Garde, Arial, sans-serif',
    fontSize: 16,
    htmlFontSize: 16,
    h1: {
      fontFamily: 'century-old-style-std, Georgia, Times New Roman, Times, serif',
      fontSize: '2.25em',
      fontWeight: 700,
      lineHeight: '1.31em',
      '@media (max-width:600px)': {
        fontSize: '1.81em',
      },
    },
    h2: {
      fontSize: '1.38em',
      fontWeight: 700,
      lineHeight: '1.36em',
      '@media (max-width:600px)': {
        fontSize: '1.38em',
      },
    },
    h3: {
      fontSize: '1.12em',
      fontWeight: 700,
      lineHeight: '1.5em',
      '@media (max-width:600px)': {
        fontSize: '1.13em',
      },
    },
    h4: {
      fontSize: '1.13em',
      fontWeight: 700,
      lineHeight: '1.6em',
      '@media (max-width:600px)': {
        fontSize: '1em',
        lineHeight: '1.44em',
      },
    },
    h5: {
      fontFamily: 'century-old-style-std, serif',
      fontSize: '1.31em',
      fontWeight: 700,
      letterSpacing: '-.01em',
      lineHeight: '1.42em',
    },
    body1: {
      fontSize: '1.13em',
      lineHeight: '1.56em',
      '@media (max-width:600px)': {
        fontSize: '1em',
        lineHeight: '1.81em',
      },
    },
    body2: {
      fontSize: '1em',
      letterSpacing: '-.01em',
      lineHeight: '1.38em',
    },
    button: {
      color: colors.white[100],
      fontSize: '1em',
      fontWeight: 700,
    },
    caption: {
      fontSize: '.88em',
      lineHeight: '1.57em',
    },
    overline: {
      color: colors.cyan[400],
      fontSize: '.88em',
      fontWeight: 700,
      letterSpacing: '.07em',
      textTransform: 'uppercase',
    },
  },
  shape: {
    borderRadius: 6,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          '& *': {
            '&:focus': {
              outline: `${colors.cyan[400]} auto 1px`,
            },
          },
        },
        body: {
          fontSize: '1em',
          lineHeight: '1.38em',
          letterSpacing: '-.01em',
          '@media only screen and (max-width: 600px)': {
            fontSize: '16px',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
        color: 'primary',
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          lineHeight: 'normal',
          padding: '1em 1.06em 1em',
          textTransform: 'inherit',
        },
        outlined: {
          border: '1px solid currentColor',
          lineHeight: 'normal',
          padding: '.81em 1.06em 1em',
          textTransform: 'inherit',

          '&:hover': {
            backgroundColor: `${colors.cyan[300]} !important`,
            borderColor: colors.cyan[300],
            color: `${colors.white[100]} !important`,
          },

          '&:focus': {
            outlineOffset: '3px',
          },
        },
        contained: {
          border: '1px solid currentColor',

          '&:hover': {
            backgroundColor: colors.cyan[300],
            borderColor: colors.cyan[300],
            '&&': {
              color: colors.white[100],
            },
          },

          '&:focus': {
            outlineOffset: '3px',
          },
        },
        containedPrimary: {
          borderColor: colors.cyan[400],
          '&&': {
            color: `${colors.white[100]}`,
          },

          '&:hover': {
            backgroundColor: colors.cyan[300],
            borderColor: colors.cyan[300],
          },

          disabled: {
            backgroundColor: colors.cyan.a400,
            borderColor: colors.cyan.a400,
            color: colors.gray.a500,

            '& .MuiButton-endIcon': {
              color: colors.gray.a500,
            },
          },
        },
        containedSecondary: {
          backgroundColor: 'transparent',
          borderColor: colors.gray[200],
          color: colors.cyan[400],

          '&:hover': {
            backgroundColor: colors.cyan[300],
            borderColor: colors.cyan[300],
            color: colors.white[100],
          },
          disabled: {
            opacity: 0.38,
          },
        },
        text: {
          color: colors.cyan[400],
          fontWeight: 400,

          '&:hover': {
            backgroundColor: 'transparent',
            color: colors.cyan[300],
          },
        },
        textPrimary: {
          color: colors.cyan[400],

          '&:hover': {
            backgroundColor: 'transparent',
            color: colors.cyan[300],
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
      styleOverrides: {
        root: {
          '&:hover': {
            color: colors.cyan[300],
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(34,34,34,0.9)',
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        input: {
          border: '2px solid transparent',
          borderRadius: '6px',

          '&:focus': {
            borderColor: colors.cyan[400],
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorDockedBottom: {
          borderTop: 0,
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        root: {
          color: colors.orange[400],
        },
        iconEmpty: {
          color: colors.gray[100],
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: colors.gray[200],
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      mobile: 375,
      tablet: 768,
      laptop: 1024,
      desktop: 1440,
    },
  },
} satisfies ThemeOptions)

export const ZipJobLandingTheme = createTheme(
  merge({}, ZipJobTheme, {
    themeName: 'ZipJob Landing',
    colors,
    palette: {
      secondary: {
        light: colors.orange[400],
        main: colors.orange['a100'],
        dark: colors.orange[600],
      },
      action: {
        disabledOpacity: 0.38,
      },
    },
    typography: {
      fontFamily: 'Lato, Trebuchet MS, Avant Garde, Arial, sans-serif',
      fontSize: 16,
      htmlFontSize: 16,
      h1: {
        fontFamily: 'century-old-style-std, Georgia, Times New Roman, Times, serif',
        fontSize: '2.8125em',
        fontWeight: 700,
        lineHeight: '1.178em',
        '@media (max-width:600px)': {
          fontSize: '2.13em',
          lineHeight: '1.26em',
        },
      },
      h2: {
        fontFamily: 'century-old-style-std, Georgia, Times New Roman, Times, serif',
        fontSize: '2.375em',
        fontWeight: 700,
        lineHeight: '1.236em',
        '@media (max-width:600px)': {
          fontSize: '1.81em',
          lineHeight: '1.31em',
        },
      },
      h3: {
        fontFamily: 'century-old-style-std, Georgia, Times New Roman, Times, serif',
        fontSize: '1.5em',
        fontWeight: 700,
        lineHeight: '1.41em',
        '@media (max-width:600px)': {
          fontSize: '1.5em',
        },
      },
      h4: {
        fontSize: '1.125em',
        fontWeight: 700,
        lineHeight: '1.56em',
        '@media (max-width:600px)': {
          fontSize: '1em',
          lineHeight: '1.69em',
        },
      },
      body1: {
        '@media (max-width:600px)': {
          lineHeight: '1.69em',
        },
      },
      body2: {
        '@media (max-width:600px)': {
          fontSize: '1.13em',
          lineHeight: '1.56em',
        },
      },
      overline: {
        color: colors.orange[600],
        fontSize: '.875em',
        fontWeight: 700,
        letterSpacing: '.07em',
        textTransform: 'uppercase',
      },
    },
  })
)

/* eslint-disable no-console */

export const placeholderFunction = (...args: unknown[]): void =>
  console.warn('placeholderFunction function called', args)

export const logErrorToConsole = (...msg: unknown[]) => console.error(...msg)

export const table = (label: string, payload: any) => {
  if (process.env.NODE_ENV === 'production') {
    return
  }

  console.groupCollapsed(label)
  console.table(payload)
  console.groupEnd()
}

export const error = (...args: any[]) => {
  if (process.env.NODE_ENV === 'production') {
    return
  }

  console.error(...args)
}

export const log = (...args: any[]) => {
  if (process.env.NODE_ENV === 'production') {
    return
  }

  console.log(...args)
}

import React, { useContext, useEffect } from 'react'
import { isBrowser } from '@talentinc/gatsby-theme-ecom/utils/env'
import useGTM from '@elgorditosalsero/react-gtm-hook'

// We set this here in case we have want to push to GTM as a part of
// a useEffect in development.
if (isBrowser && !window.dataLayer) {
  window.dataLayer = []
}

const GoogleTagManagerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // Google Tag Manager is init'd by Gatsby, we are just using this hook so we
  // don't need to do unfun Typescript stuff everywhere to send data to GTM.
  const { UseGTMHookProvider, useGTMHookContext } = useGTM()
  // We don't use GTM in local dev, so we have to polyfill it if it isn't
  // already set.
  const { dataLayerName = 'dataLayer' } = useContext(useGTMHookContext) || {}

  useEffect(() => {
    if (isBrowser && !window[dataLayerName]) {
      window[dataLayerName] = []
    }
  }, [dataLayerName])

  return <UseGTMHookProvider>{children}</UseGTMHookProvider>
}

export default GoogleTagManagerProvider

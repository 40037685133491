import React from 'react'
import { ThemeProvider, Theme } from '@mui/material'
import Helmet from 'react-helmet'
import useSiteMetadata from '../../hooks/useSiteMetadata'
import { AxiosProvider } from '../../hooks/useAxios'
import GoogleTagManagerProvider from './GoogleTagManager'
import { PageMeasurementProvider } from '../../hooks/usePageMeasurements'
import { EventBusProvider } from '../../hooks/useEvents'
import { ContentfulABTestProvider } from '../../hooks/useContentfulABTest'
import Intercom from './Intercom'
import UserSessionProvider from './UserSession'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { LeadActionsProvider } from '../../hooks/useLeadActions'

interface RootElementProviderProps {
  theme: Theme
  children: React.ReactNode
}

const RootElementProvider: React.FC<RootElementProviderProps> = ({
  children,
  theme,
}) => {
  const { title } = useSiteMetadata()
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // Disabling this so we don't DDoS the server on accident
        // This should be enabled on a case by case basis on lite routes
        // relevant to the user, like their session
        refetchOnWindowFocus: false,
      },
    },
  })
  return (
    <ThemeProvider theme={theme}>
      <AxiosProvider>
        <QueryClientProvider client={queryClient}>
          <GoogleTagManagerProvider>
            <EventBusProvider>
              <ContentfulABTestProvider>
                <LeadActionsProvider>
                  <UserSessionProvider>
                    <PageMeasurementProvider>
                      <Helmet titleTemplate={`%s | ${title}`} />
                      {children}
                      {/* <CookieBanner /> */}
                      <Intercom />
                      <ReactQueryDevtools initialIsOpen={false} />
                    </PageMeasurementProvider>
                  </UserSessionProvider>
                </LeadActionsProvider>
              </ContentfulABTestProvider>
            </EventBusProvider>
          </GoogleTagManagerProvider>
        </QueryClientProvider>
      </AxiosProvider>
    </ThemeProvider>
  )
}

export default RootElementProvider

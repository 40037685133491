import { useContext } from 'react'

import {
  UserSessionProviderValue,
  UserSessionContext,
} from '@talentinc/gatsby-theme-ecom/components/Providers/UserSession'

/**
 * Provides basic data for the lead/user.
 *
 * Note: I'm calling this hook useUser to match patterns in other code bases,
 * even though for this application, this user is canoncially a lead.
 */
export default function useUser(): UserSessionProviderValue {
  const context = useContext(UserSessionContext)
  if (!context) {
    throw new Error('useUser can only be used within UserSessionProvider')
  }
  return context
}
